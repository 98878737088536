import {
  isSdkMode,
  isPopupMode,
  isFullPageRedirectionMode,
} from '../utility/inContextDonation';
import { FPTI_TAGS, PAGE_SPEC, FPTI_VALUES } from './fptiConstants';
import templateData from '../utility/getTemplateData';
import {
  isAtLandingPage,
  isOptInCoverFee,
} from '../components/coverFee/feeUtils';
import AppStore from '../stores/AppStore';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  isExternalFlow,
  isGNC,
  isPPFundraising,
} from '../utility/productIntegrationUtils';
import {
  EVENT_NAME,
  FPTI_PRODUCT,
  INTERNAL_ERROR,
  DNW_UNHANDLED_ERROR,
} from '../utility/constants';
import { isLoggedInUser } from '../utility/authUtils';

const {
  anonymousEnabled,
  coverFeeEnabled,
  donationAmount,
  donationCode,
  donationName,
  donorIpCountry,
  feeAmount,
  feeCurr,
  feePayer,
  fptPageName,
  npid,
  product,
  productId,
  token,
  integrationIdentifier,
  recurringEnabled,
  supportedRecurringFrequencies,
} = templateData;

export const getUiType = () => {
  if (!isSdkMode()) {
    return 'full_page';
  }

  if (isPopupMode()) {
    return 'popup';
  }

  if (isFullPageRedirectionMode()) {
    return 'popup_blocked';
  }
};

export const getDonationAmount = () => {
  const amount = AppStore.getDonationAmount() || donationAmount;
  return {
    [FPTI_TAGS.AMOUNT]: amount,
  };
};

export const getFeeDataFromTemplateData = () => {
  return {
    ...(feePayer
      ? {
          [FPTI_TAGS.FEE_PAYER]: feePayer,
          [FPTI_TAGS.FEE_AMOUNT]: feeAmount,
          [FPTI_TAGS.FEE_CURRENCY]: feeCurr,
        }
      : {}),
  };
};

export const getFeeData = () => {
  return {
    ...(coverFeeEnabled
      ? {
          [FPTI_TAGS.FEE_PAYER]: isOptInCoverFee() ? 'consumer' : 'merchant',
          [FPTI_TAGS.FEE_AMOUNT]: AppStore.getFeeAmount(),
          [FPTI_TAGS.FEE_CURRENCY]: AppStore.getDonationCode(),
        }
      : {}),
  };
};

export const getAnonymousStatus = () => {
  return {
    ...(anonymousEnabled
      ? {
          [FPTI_TAGS.NONANONYMOUS]:
            AppStore.getAnonymousStatus() === 'unchecked',
        }
      : {}),
  };
};

export const getNoteData = (data) => {
  return {
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.NOTE_ALLOWED]: data?.noteFieldDisplayed || false,
          [FPTI_TAGS.NOTE_ADDED]: !_isEmpty(AppStore.getDonationNote()),
        }
      : {}),
  };
};

export const getRecurringStatus = () => {
  return {
    ...(recurringEnabled
      ? {
          [FPTI_TAGS.IS_RECURRING]:
            AppStore.getDonationRecurring() === 'checked',
        }
      : {}),
  };
};

export const setEnabledRecurringFrequency = () => {
  if (recurringEnabled) {
    window.fpti[FPTI_TAGS.ENABLED_FREQUENCIES] =
      supportedRecurringFrequencies?.length > 0
        ? supportedRecurringFrequencies.join(',').toUpperCase()
        : 'MONTH';
  }
};

export const getSelectedRecurringFrequency = () => {
  if (supportedRecurringFrequencies?.length > 0) {
    return {
      [FPTI_TAGS.SELECTED_FREQUENCY]:
        AppStore.getSelectedRecurringFrequency()?.toUpperCase(),
    };
  }
  return {
    [FPTI_TAGS.SELECTED_FREQUENCY]:
      AppStore.getDonationRecurring() === 'checked' ? 'MONTH' : null,
  };
};

export const getFeePayer = () =>
  coverFeeEnabled && {
    [FPTI_TAGS.FEE_PAYER]: isOptInCoverFee() ? 'consumer' : 'merchant',
  };

export const addFeature = (feature) => {
  if (window.fpti[FPTI_TAGS.FEATURE]) {
    const featuresArray = window.fpti[FPTI_TAGS.FEATURE].split(',');
    if (!featuresArray.includes(feature)) {
      window.fpti[FPTI_TAGS.FEATURE] += ',' + feature;
    }
  } else {
    window.fpti.feature = feature || '';
  }
};

export const setAmountTypeFpti = (value) => {
  const amttyp = window.fpti[FPTI_TAGS.AMOUNT_TYPE];

  if (amttyp !== value) {
    window.fpti[FPTI_TAGS.AMOUNT_TYPE] = value;
  }
};

export const trackSelectedPresetAmount = (value) => {
  const opsl = window.fpti[FPTI_TAGS.SELECTED_PRESET_BUTTON_ID];
  if (opsl !== value) {
    window.fpti[FPTI_TAGS.SELECTED_PRESET_BUTTON_ID] = value;
  }

  setAmountTypeFpti(value === 'other' ? 'any' : 'preset');
};

export const getLandingPageData = ({ noteFieldDisplayed }) => {
  return {
    ...getFeeData(),
    ...getDonationAmount(),
    ...getAnonymousStatus(),
    ...getNoteData({ noteFieldDisplayed }),
    ...getRecurringStatus(),
    ...PAGE_SPEC.LANDING_PAGE,
  };
};

export const getThreeDSData = () => {
  const fundingOpts = AppStore.getFundingOptions();
  const contingencies = _get(fundingOpts, 'contingencies') || [];
  const threeDSContingency =
    _find(contingencies, { action: '3D_SECURE_DATA_COLLECTION_REQUIRED' }) ||
    {};

  return {
    [FPTI_TAGS.FI_ID]: _get(fundingOpts, 'id'),
    [FPTI_TAGS.FI_TYPE]: _get(fundingOpts, 'sources[0].payment_card.type'),
    [FPTI_TAGS.FI_NETWORK]: _get(
      fundingOpts,
      'sources[0].payment_card.network'
    ),
    [FPTI_TAGS.FI_OPERATION]: 'add_card',
    [FPTI_TAGS.IS_3DS_ELIGIBLE]: true,
    [FPTI_TAGS.THREE_DS_VERSION]: '3DS2.0',
    [FPTI_TAGS.THREE_DS_REF_ID]: _get(
      threeDSContingency,
      'three_ds_reference_id'
    ),
    [FPTI_TAGS.URL]: _get(threeDSContingency, 'device_data_collection_url'),
  };
};

export const getExternalFlowData = () => {
  return {
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.PRODUCT]: FPTI_PRODUCT,
          [FPTI_TAGS.FLOW]: product,
          [FPTI_TAGS.INTEGRATION_IDENTIFIER]: integrationIdentifier,
          [FPTI_TAGS.DONOR_IP_COUNTRY]: donorIpCountry,
          [FPTI_TAGS.ACCOUNT_TYPE]: isLoggedInUser() ? 'member' : 'guest',
          [FPTI_TAGS.CURRENCY]: donationCode,
          ...(!_isEmpty(productId)
            ? {
                [FPTI_TAGS.CAMPAIGN_ID]: productId,
              }
            : {}),
          ...(!_isEmpty(npid)
            ? {
                [FPTI_TAGS.NPID]: npid,
              }
            : {}),
          ...(isGNC() || isPPFundraising()
            ? {
                [FPTI_TAGS.CHARITY_NAME]: donationName,
              }
            : {}),
        }
      : {}),
  };
};

export const getToken = () => {
  return {
    [FPTI_TAGS.TOKEN]: token,
  };
};

export const updateGlobalRespDuration = (respDuration) => {
  const globalRespDuration = window.fpti.resp_duration || {};
  window.fpti.resp_duration = {
    ...globalRespDuration,
    ...respDuration,
  };
};

export const updateGlobalFPTI = (data) => {
  window.fpti = {
    ...window.fpti,
    ...data,
    [FPTI_TAGS.SPACE_KEY]: FPTI_VALUES.SPACE_KEY_ID,
  };
};

export const getDefaultPageSpec = () => {
  const url = window.location.href;
  const isOnboardingFlow = fptPageName === 'guestULSignUp';

  const pageSpec = _find(PAGE_SPEC, (page) => {
    return page.url && url.indexOf(page.url) > -1;
  });
  if (pageSpec) {
    return pageSpec;
  }

  if (url.indexOf('/donate/guest/confirmation') > -1) {
    return isOnboardingFlow
      ? PAGE_SPEC.ONBOARDING_GUEST_SUCCESS_PAGE
      : PAGE_SPEC.GUEST_SUCCESS_PAGE;
  } else if (url.indexOf('/donate/guest/pending') > -1) {
    return isOnboardingFlow
      ? PAGE_SPEC.ONBOARDING_GUEST_PENDING_PAGE
      : PAGE_SPEC.GUEST_PENDING_PAGE;
  } else if (!isAtLandingPage()) {
    return PAGE_SPEC.ONBOARDING_GUEST_FORM;
  } else if (url.indexOf('/donate') > -1) {
    return PAGE_SPEC.LANDING_PAGE;
  }
  return pageSpec;
};

export const getConfirmationData = (confirmationData) => {
  return {
    [FPTI_TAGS.AMOUNT]: confirmationData?.postBackData?.mc_gross,
    [FPTI_TAGS.CURRENCY]: confirmationData?.postBackData?.mc_currency,
    [FPTI_TAGS.TXN_ID]: confirmationData?.confirmationNumber || '',
    [FPTI_TAGS.SENDER_ID]: confirmationData?.postBackData?.payer_id,
    ...(confirmationData?.orderId
      ? {
          [FPTI_TAGS.ORDER_ID]: confirmationData.orderId,
        }
      : {}),
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.EVENT_NAME]: confirmationData?.isOfacPending
            ? EVENT_NAME.DW_GIVING_PAYMENT_PENDING_SCREEN_SHOWN
            : EVENT_NAME.DW_GIVING_PAYMENT_SUCCESS_SCREEN_SHOWN,
        }
      : {
          [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATION_CONFIRMATION_PAGE_IMP,
        }),
    ...getFeePayer(),
  };
};

export const checkAndReplaceInternalErrWithDnwErr = (errorCode) => {
  return errorCode === INTERNAL_ERROR ? DNW_UNHANDLED_ERROR : errorCode;
};

export const updateFPTIValue = (key, value) => {
  if (window.fpti[key]) {
    const valueArray = window.fpti[key]?.split(',');
    if (valueArray && !valueArray.includes(value)) {
      window.fpti[key] += ',' + value;
    }
  } else {
    window.fpti[key] = value || '';
  }
};
