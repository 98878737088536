import _fromPairs from 'lodash/fromPairs';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { location } from '../routes/configureHistory';

export function parseUrlQuery(search = location.search) {
  const queryParams = _fromPairs(
    _compact(
      _map(
        search.slice(1).split('&'),
        (item) => item && item.split('=').map(decodeURIComponent)
      )
    )
  );

  return queryParams || {};
}

export const addParameterToURL = (url, key, value) => {
  url += (url.split('?')[1] ? '&' : '?') + `${key}=${value}`;
  return url;
};
