import { datadogRum } from '@datadog/browser-rum';

const packageJson = require('../../../package.json');

const SERVICE_NAME = 'donatenodeweb';
const APPLICATION_ID = {
  PRODUCTION: 'ea0d0f72-7e9c-4439-93d1-bdfd24a8a58b',
  SANDBOX: '4ee30f74-f5c6-42b4-a4df-006566b81b6d',
  QA: '689e1559-2dca-41ab-b6b3-9f587c025cbc',
};
const CLIENT_ID = {
  PRODUCTION: 'pub5f2f9c1a22512ec8c3669abb873b07c8',
  SANDBOX: 'pubdb2edd9c3e87719370e624ef2bba7a54',
  QA: 'pubc5e2f2d78230c796fbe543e12ff75e1f',
};

const DATADOG_RUM_SITE = 'us5.datadoghq.com';

function getClientEnvironment() {
  const hostname = typeof window !== 'undefined' ? window.location.host : '';
  if (hostname.includes('localhost')) return 'DEVELOPMENT';
  if (hostname.includes('qa.paypal.com')) return 'STAGE';
  if (hostname.includes('sandbox.paypal.com')) return 'SANDBOX';
  if (hostname.includes('paypal.com')) return 'PRODUCTION';
}

function getDDTraceLink(correlationId) {
  const environment = getClientEnvironment();
  switch (environment) {
    case 'PRODUCTION':
      return `https://go/ddprod/trace?${correlationId}`;
    case 'SANDBOX':
      return `https://go/ddsb/trace?${correlationId}`;
    default:
      return `https://go/ddqa/trace?${correlationId}`;
  }
}

function getCalLogLink(correlationId) {
  const environment = getClientEnvironment();
  switch (environment) {
    case 'PRODUCTION':
      return `https://go/ddprod/tocal?${correlationId}`;
    case 'SANDBOX':
      return `https://go/ddsb/tocal?${correlationId}`;
    default:
      return `https://go/ddqa/tocal?${correlationId}`;
  }
}

export function getApplicationId() {
  const environment = getClientEnvironment();
  switch (environment) {
    case 'PRODUCTION':
      return APPLICATION_ID.PRODUCTION;
    case 'SANDBOX':
      return APPLICATION_ID.SANDBOX;
    default:
      return APPLICATION_ID.QA;
  }
}

export function getClientToken() {
  const environment = getClientEnvironment();
  switch (environment) {
    case 'PRODUCTION':
      return CLIENT_ID.PRODUCTION;
    case 'SANDBOX':
      return CLIENT_ID.SANDBOX;
    default:
      return CLIENT_ID.QA;
  }
}

export function addCorrelationIdToContext(event, correlationId) {
  if (correlationId && typeof correlationId === 'string') {
    event.context.correlationId = correlationId;

    const ddTraceLink = getDDTraceLink(correlationId);
    const calLogLink = getCalLogLink(correlationId);
    event.context['dd-trace-url'] = ddTraceLink;
    event.context['external-cal-url'] = calLogLink;
  }
}

/* istanbul ignore next */
if (typeof window !== 'undefined') {
  // init Datadog RUM
  /* istanbul ignore next */
  datadogRum.init({
    // properties for idntifying and authenticating the application with RUM
    applicationId: getApplicationId(),
    clientToken: getClientToken(),
    site: DATADOG_RUM_SITE,
    service: SERVICE_NAME,
    version: packageJson.version,
    env: process.env.NODE_ENV,
    // sampling must be set at 100 for session and 0 for recording. retention will be controlled on the backend in Datadog
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    // auto-instrumentations
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // privacy defaults to prevent leaking PII
    defaultPrivacyLevel: 'mask',
    enablePrivacyForActionName: true,
    // exclude log, tealeaf, and fpti endpoints which are not tied to user interactions from activity tracking
    excludedActivityUrls: [/log/i, /fpti/i, /tealeaf/i, /tracking\/ts/i],
    // exclude log, tealeaf, and fpti endpoints which are not tied to user interactions from universal tracing
    allowedTracingUrls: [
      (url) =>
        !(
          url.includes('log') ||
          url.includes('fpti') ||
          url.includes('tealeaftarget') ||
          url.includes('tracking/ts')
        ) && url.includes('paypal.com'),
    ],
    beforeSend(event, context) {
      // modify RUM events to include the correlationId and links to logs and traces
      // for the request because we don't yet have universal tracing
      if (event.type === 'resource') {
        // handle fetch
        if (
          'response' in context &&
          context?.response &&
          context?.response?.headers
        ) {
          const correlationId = context.response.headers.get('Paypal-Debug-Id');
          addCorrelationIdToContext(event, correlationId);
        }
        // handle XHR
        if (
          'xhr' in context &&
          context.xhr &&
          'getResponseHeader' in context.xhr &&
          typeof context.xhr.getResponseHeader === 'function'
        ) {
          const correlationId =
            context.xhr.getResponseHeader('Paypal-Debug-Id');
          addCorrelationIdToContext(event, correlationId);
        }
      }
      return true;
    },
  });
}
