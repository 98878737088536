module.exports = {
  postbackKeys: {
    TXN_ID: 'txn_id',

    MC_CURRENCY: 'mc_currency',
    MC_FEE: 'mc_fee',
    MC_GROSS: 'mc_gross',

    PAYMENT_DATE: 'payment_date',
    PAYMENT_FEE: 'payment_fee',
    PAYMENT_GROSS: 'payment_gross',
    PAYMENT_STATUS: 'payment_status',
    PAYMENT_TYPE: 'payment_type',

    BUSINESS: 'business',
    RECEIVER_EMAIL: 'receiver_email',
    RECEIVER_ID: 'receiver_id',

    CONTACT_PHONE: 'contact_phone',
    PAYER_EMAIL: 'payer_email',
    PAYER_ID: 'payer_id',
    PAYER_STATUS: 'payer_status',

    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',

    ADDRESS_NAME: 'address_name',
    ADDRESS_STREET: 'address_street',
    ADDRESS_CITY: 'address_city',
    ADDRESS_STATE: 'address_state',
    ADDRESS_COUNTRY_CODE: 'address_country_code',
    ADDRESS_ZIP: 'address_zip',

    RESIDENCE_COUNTRY: 'residence_country',

    ITEM_NAME: 'item_name',
    ITEM_NUMBER: 'item_number',
    INVOICE: 'invoice',
    QUANTITY: 'quantity',
    CUSTOM: 'custom',

    PAYMENT_STATUS_RECURRING: 'paymentStatus',
    CONFIRMATION_NUMBER: 'confirmationNumber',
  },

  guestFormFiled: [
    {
      name: 'amount',
      code: 'REQUIRED_AMOUNT',
    },
    {
      name: 'symbol',
      code: 'REQUIRED_SYMBOL',
    },
    {
      name: 'country',
      code: 'REQUIRED_COUNTRY',
    },
    {
      name: 'firstname',
      code: 'REQUIRED_FIRST_NAME',
    },
    {
      name: 'lastname',
      code: 'REQUIRED_LAST_NAME',
    },
    {
      name: 'phonenumber',
      code: 'REQUIRED_PHONE_NUMBER',
    },
    {
      name: 'email',
      code: 'MISSING_EMAIL_ID',
    },
    {
      name: 'cardnumber',
      code: 'REQUIRED_CARD_NUMBER',
    },
    {
      name: 'csc',
      code: 'REQUIRED_SECURITY_CODE',
    },
    {
      name: 'expiration',
      code: 'REQUIRED_EXPIRY',
    },
    {
      name: 'billingAddress',
      code: 'REQUIRED_BILLING_ADDRESS',
      embend: [
        {
          name: 'streetaddress1',
          code: 'REQUIRED_ADDRESS_LINE1',
        },
        {
          name: 'city',
          code: 'REQUIRED_BILLING_ADDRESS_CITY',
        },
        {
          name: 'state',
          code: 'REQUIRED_BILLING_ADDRESS_STATE',
        },
        {
          name: 'zipcode',
          code: 'REQUIRED_BILLING_ADDRESS_ZIP',
        },
        {
          name: 'country',
          code: 'REQUIRED_BILLING_ADDRESS_COUNTRY',
        },
      ],
    },
    {
      name: 'createaccount',
      code: '',
    },
    {
      name: 'password',
      code: 'PASSWORD_REQUIRED',
    },
  ],

  // UL page tag line content key enum
  UL_TAG_LINE_CONTENT_KEY_ENUM: {
    DONATE_WITH_PAYPAL: 'donateWithPayPal',
  },

  // Sign Up Button key in UL
  SIGNUP_BUTTON_CONTENT_KEY_ENUM: {
    CREATE_ACCOUNT: 'createAccount',
    DONATE_WITH_CARD: 'donateWithCard',
  },

  // Login with credentials
  LOGIN_OPTIONS_ENUM: {
    FORCED: 1, // Login is forced each time
    NOT_FORCED: 2, // Login is not forced each time
  },

  // Contextual Login
  ONE_TOUCH_OPTIN_ENUM: {
    ENABLED: 1, // OneTouch optin is shown
    DISABLED: 2, // OneTouch optin is not shown
  },
  CONFIRM_EMAIL_TOPIC: 'activation-confirmation-email-251',
  // Button factory
  BUTTON_FACTORY_CONSTANTS: {
    BUTTON_FACTORY_INTERNAL_SERVER_ERROR:
      'BUTTON_FACTORY_INTERNAL_SERVER_ERROR',
    BFS_RESPONSE: 'BFS_RESPONSE',
    BFS_RESPONSE_ALT_TEXT: 'BFS_RESPONSE_ALT_TEXT',
    HANDCRAFT_SECURITY_CONTEXT: 'HANDCRAFT_SECURITY_CONTEXT',
    ERROR_SET_BUSINESS_INFO: 'ERROR_SET_BUSINESS_INFO',
    ERROR_SET_MERCHANT_SETTINGS: 'ERROR_SET_MERCHANT_SETTINGS',
    ERROR_SET_GLOBALNAV_HEADER_FOOTER: 'ERROR_SET_GLOBALNAV_HEADER_FOOTER',
  },
  FRS_CONSTANTS: {
    BADGE_INFO: 'FIRST,TOP,SELF',
  },
  PPCC_ELIGIBILITY_CODE_CONSTANTS: [
    'PPCC_NOT_CONFIRMED',
    'PPCC_NOT_APPLICABLE',
  ],
  CIP_ELIGIBILITY_CODE_CONSTANT: 'RECEIVER_CIP_FAILED',
  PPCC_ELIGIBILITY_PERIOD_CONSTANTS: ['WARNING', 'ACTIVE'],
  PPCC_ELIGIBILITY_ACTION_CONSTANTS: {
    WARNING: 'PPCC_WARN',
    ACTIVE: 'PPCC_ACTIVE',
  },
  CIP_STATUS_ACTION_CONSTANTS: {
    NOT_VERIFIED: 'NOT_VERIFIED',
    PENDING: 'PENDING',
    FIRST_TIME_VERIFIED: 'FIRST_TIME_VERIFIED',
  },
  CIP_PROCESS_STATUS_CONSTANTS: {
    FAILED: ['FAILED', 'DENY', 'NEED_MORE_DATA', 'EXPIRED'],
    PENDING: ['MANUAL_REVIEW', 'PENDING_SYSTEM_PROCESSING'],
    SUCCESS: ['COMPLETED', 'ALLOW', 'NOT_APPLICABLE', 'EXEMPTED'],
  },
  CIP_BUSINESS_CATEGORIES: [
    'PROPRIETORSHIP',
    'INDIVIDUAL',
    'PROPRIETORSHIP_CRAFTSMAN',
    'PROPRIETORY_COMPANY',
    'LIMITED_LIABILITY_PROPRIETORS',
    'ONLY_BUY_OR_SEND_MONEY',
  ],
  HOME_ROUTE: ['/buttons', '/buttons/'],
  CHARITY_STATUS: {
    confirmed: 'CONFIRMED',
  },
  HTTP_STATUS_CODE: {
    success: 200,
  },
  HTTP_METHOD: {
    GET: 'GET',
  },
  RISK_STATUS: {
    DENY: 'DENY',
    SERVER_ERROR: 'SERVER_ERROR',
    SUCCESS: 'SUCCESS',
  },
};
